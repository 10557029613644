<template>
  <v-dialog
    v-model="add"
    :scrollable="true"
    width="768"
    @keydown.enter="addNewItem"
    @keydown.esc="close"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="body-1 elevation-1 mb-1 font-weight-bold py-3">
        <span>{{ $t('ui.add') }} {{ $t(typeConfig.verbose_label_singular) }}</span>
        <v-spacer />
        <type-fields
          v-if="statusField !== undefined"
          :fields="[statusField]"
          :type-name="typeName"
          :data="formData"
          :grid="false"
          @update:data="handleUpdateData"
        />
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container
          grid-list-md
          class="pa-0"
        >
          <!-- form -->
          <v-form ref="form-add">
            <template v-for="(group, index) in getGroupPanels">
              <div
                v-show="showCurrentGroupFields(group.key)"
                :key="group.key"
                class="my-4"
              >
                <h3 v-t="group.label" />
                <v-divider class="mb-4" />
                <type-fields
                  :fields="currentGroupFields(groupFields === null ? undefined : group.key)"
                  :data="formData"
                  :is-add-item="true"
                  :type-name="typeName"
                  :auto-focus="index === 0"
                  @update:data="handleUpdateData"
                />
              </div>
            </template>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!-- Cancel -->
        <v-btn
          text
          @click.native="close($event)"
        >
          {{ $t('ui.cancel') }}
        </v-btn>
        <!-- Submit -->
        <v-btn
          :loading="loadingSave"
          :disabled="disableButton"
          color="primary"
          dark
          @click.native="addNewItem"
        >
          {{ $t('ui.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import gql from 'graphql-tag';
import typeConfigMixin from '../../mixins/typeConfig';
import queriesMixin from '../../mixins/queries';

export default {
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
  ],
  props: {
    add: {
      type: Boolean,
      default: false,
    },
    typeName: {
      type: String,
      required: true
    },
    isRelation: {
      type: Boolean,
      default: false,
    },
    hiddenAddFields: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      formData: {},
      loadingSave: false,
      disableButton: false,
    };
  },
  computed: {
    /*
     * Only populate groupPanels when add-item dialog is open
     * This removes previous field validation errors when dialog is closed
     *
     * Returns {array}
     */
    getGroupPanels () {
      return this.add === true ? this.groupPanels : [];
    },
    statusField () {
      return this.addFields.find(field => field.key === this.typeConfig.itemStatus);
    },
    currentFieldsExceptStatus () {
      return this.addFields.filter(field => field.key !== this.typeConfig.itemStatus);
    },
  },
  watch: {
    // Reset form data
    add () {
      this.resetFormData();
    },
  },
  methods: {
    ...mapActions('crud', ['setAddFieldsWithErrors']),
    /*
    * Reset values in form
    * @returns {void}
    */
    resetFormData () {
      this.formData = {};
      this.setAddFieldsWithErrors([]);
    },
    /**
     * By default, we show all the fields if the current module has no groupsFields. If the
     * module has groupsFields (this.groupFields !== null), we check if the current looped
     * group has active children (in this case, if those fields are "add === true")
     * @param {String} key - The key name of the current group. Used to filter children availability.
     * @returns {Boolean}
     */
    showCurrentGroupFields (key) {
      let showCurrentGroupFields = true;

      if (this.groupFields !== null) {
        showCurrentGroupFields = this.currentFieldsExceptStatus.some(field => field.groupkey === key);
      }

      return showCurrentGroupFields;
    },
    /**
     * Give all fields (except Status) that should be shown inside the current group. If there is no group
     * in the module, it returns all the fields without filtering (again, except Status).
     * @param {String} key - The key name of the current group. Used to filter fields related to each group.
     * @returns {Array}
     */
    currentGroupFields (key) {
      let fields = [];

      if (key !== undefined) {
        fields = this.currentFieldsExceptStatus.filter(field => field.groupkey === key);
      } else {
        fields = this.currentFieldsExceptStatus;
      }

      return fields;
    },
    getFieldDefinition (key) {
      return this.fields.find(field => field.key === key);
    },
    async addNewItem () {
      const inputsInFormData = Object.keys(this.formData);
      const requiredInputsEmpty = this.addFields
        .filter(field => field.required === true && inputsInFormData.includes(field.key) === false);
      this.setAddFieldsWithErrors(requiredInputsEmpty);

      if (this.$refs['form-add'].validate() && requiredInputsEmpty.length === 0) {
        this.loadingSave = true;
        const { formData } = this;
        const mutationName = this.getAddMutationName(this.typeConfig.idSingular);
        try {
          const fieldsForMutation = this.addFields;
          const hiddenFields = this.hiddenAddFields ? Object.entries(this.hiddenAddFields) : [];
          if (hiddenFields.length > 0) {
            hiddenFields.forEach(([key, value]) => {
              fieldsForMutation.push({ key, type: 'number', removeFromSelection: true });
              this.formData[key] = value;
            });
          }
          const { data } = await this.executePatchMutation({
            mutationName,
            targetId: null,
            fields: fieldsForMutation,
          }, this.formData);

          if (this.isRelation === true) {
            this.$emit('add-relation', data[mutationName].id);
          } else {
            this.$emit('refetch');
          }

          this.$reportSuccess({ message: this.$t('dialogs.add_success') });
          this.loadingSave = false;
          this.close();
        } catch (error) {
          const messages = get(error, 'graphQLErrors', [{ message: error.message }]);
          console.error(error);
          this.loadingSave = false;
          this.$reportError(messages);
        }
      }
    },
    handleUpdateData ({ key, value }) {
      this.$set(this.formData, key, value);
    },
    close ($event) {
      this.$emit('close');
    },
  }
};
</script>
