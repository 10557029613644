import iconv from 'iconv-lite';
import XLSX from 'xlsx';
import { has, get } from 'lodash';
import striptags from 'striptags';
import * as moment from 'moment';
import { mapActions, mapState, mapGetters } from 'vuex';


export default {
  data () {
    return {
      exportOptions: [
        {
          label: 'XLS',
          exportType: 'xls',
          icon: 'vertical_align_bottom',
        },
        {
          label: 'CSV',
          exportType: 'csv',
          icon: 'vertical_align_bottom',
        },
      ],
    };
  },
  computed: {
    ...mapState('crud', ['exporting']),
    ...mapGetters('crud', ['isExporting']),
  },
  methods: {
    ...mapActions('crud', ['setExporting']),
    /**
     * Organise listing information and trigger action
     *
     * @return {Object}  result           Headers, Rows and typeName for that Listing
     *          {array}  result.headers   Listing headers
     *          {array}  result.listing   Listing rows
     *          {String} result.typeName  Name of type being exported, used for file name
     */
    async getListingRowsForExport () {
      if (this.typeConfig.customExport === true) {
        const filters = this.getFilterValue().replaceAll(':', '=').replaceAll(',', '&');
        const data = await this.axios.get(
          `/api/exports/${this.typeName}?search=${this.search}${filters.length > 0 ? `&${filters}` : ''}`,
          {
            baseURL: process.env.VUE_APP_API_ROOT
          },
        );
        return {
          headers: get(data, 'data.headers', []),
          listing: get(data, 'data.data', []),
          filename: get(data, 'data.filename', null),
          typeName: this.typeName,
        };
      } else {
        const exportOptions = {
          sortBy: this.getSortByValue(),
          descending: this.getDescendingValue(),
          filter: this.getFilterValue(),
          fields: this.listingFetchFields,
          type: this.typeName,
          page: 1,
          itemsPerPage: -1,
        };

        if (this.search != null && this.search !== '') {
          exportOptions.searchFields = this.searchFields.map(field => field.key).join();
          exportOptions.search = this.search;
        }

        // Get all lines
        const data = await this.$apollo.query({
          query: this.createListingQuery(exportOptions),
        });
        return {
          headers: this.headers,
          listing: has(data, 'data.rows') ? data.data.rows : [],
          typeName: this.typeName,
        };
      }
    },
    /**
     * Export XLS, generate a XLS document of the listing
     *
     * @return {void}
     */
    async exportXLS () {
      this.setExporting({ format: 'xls', isExporting: true });
      const { headers, listing, typeName, filename } = await this.getListingRowsForExport();
      // Filename
      const name = filename ? `${filename}.xlsx` : `${typeName}-listing-${moment().format('YYYY-MM-DD_hhmmss')}.xlsx`;
      // Create Workbook
      const wb = XLSX.utils.book_new();
      // Initialize worksheet with headers
      const excelHeaders = headers.filter(header => header.value !== 'action');
      const wsData = [[...excelHeaders.map(res => res.text || res.value)]];

      // Add info for each entry
      listing.forEach(entry => {
        const entryInfo = excelHeaders.map((header) => {
          return this.getEscapedValue(entry, header);
        });
        wsData.push(entryInfo);
      });

      // Generate worksheet from data
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, typeName);

      // Generate file
      XLSX.writeFile(wb, name);
      this.setExporting({ format: 'xls', isExporting: false });
    },
    /**
     * Export CSV, generate a CSV document of the listing
     *
     * @return {void}
     */
    async exportCSV () {
      this.setExporting({ format: 'csv', isExporting: true });
      const { headers, listing, typeName, filename } = await this.getListingRowsForExport();
      const name = filename ? `${filename}.csv` : `${typeName}-listing-${moment().format('YYYY-MM-DD_hhmmss')}.csv`;
      const csvRows = [];
      // Remove column for actions
      const simplifiedHeaders = headers.filter(({ value }) => ( value !== 'action'));
      // Add Header row in CSV
      csvRows.push(simplifiedHeaders.map(({ value }) => value));
      // Add listing information in csv rows
      listing.forEach(entry => {
        const entryInfo = simplifiedHeaders.map((header) => {
          // For CSV, we must wrap in double quotes
          return `"${this.getEscapedValue(entry, header)}"`;
        });
        csvRows.push(entryInfo.join(','));
      });
      const joinRows = csvRows.join('\n');

      // Have to encode in ISO-8859-1 Latin-1
      const buf = iconv.encode(joinRows, 'ISO-8859-1');

      // Download CSV on client-side
      const blob = new Blob([buf], {
         type: 'text/csv;charset=ISO-8859-1',
         encoding: 'ISO-8859-1',
       });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', name);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.setExporting({ format: 'csv', isExporting: false });
    },
    /**
     * Helper method to get escaped string for each column of row
     *
     * @params {Object} entry   The listing row being exported
     * @params {Object} Header  The header (column) being exported
     *
     * @return {String} escaped string
     */
     getEscapedValue (entry, header) {
       const { value, type, items, itemText } = header;
        // Find entry value for this header
        let entryValue = entry[value];
        // Special case for status, we must replace 0,1,2s with Online/Offline/Pending
        if (type === 'status') {
          // Use items from header config or use default item statuses instead.
          const statusItems = items !== undefined
            ? items
            : this.$xms.config.modules.crud.itemsStatusFallback;
          if (statusItems !== undefined) {
            const status = statusItems.find(item => item.value === entry[value]);
            if (status !== undefined) {
              entryValue = this.$t(status.text);
            }
          }
        }
        // Avoid 'null' entries
        if (entryValue === null) entryValue = '';
        // If entryValue is an object, take title by default or use itemText property if it exists
        if (typeof entryValue === 'object') {
          entryValue = itemText !== undefined ? entryValue[itemText] : entryValue.title;
        }
        // By default, escape the value to avoid quotes, and remove html tags
        return striptags(String(entryValue).replace(/"/, '\\"'));
     }
  },
};
